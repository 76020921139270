// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class EmailService {
    getAllEmails() {
        return axios.get(API_URL + 'emails', { headers: authHeader() })
    }
    getCategories() {
        return axios.get(API_URL + 'notification-groups', { headers: authHeader() })
    }
    deleteEmail(resource) {
        return axios.delete(API_URL + 'emails/' + resource.id, { headers: authHeader() })
    }
    updateEmail(resource) {
        return axios.put(API_URL + 'emails/' + resource.id, resource, { headers: authHeader() })
    }
    storeEmail(resource) {
        return axios.post(API_URL + 'emails' , resource, { headers: authHeader() })
    }
}

export default new EmailService()
