<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="12" sm="5" class="mt-5">
            <v-toolbar-title
              class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
            >
              <v-icon class="mr-2 mt-n1" color="error"
                >mdi-email-box</v-icon
              >User Emails
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">List of all your emails</p>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>
          <v-col cols="12" sm="3" class="mt-5">
            <v-select
              :items="notificationGroups"
              label="Select Group"
              item-text="name"
              item-value="id"
              small
              dense
              persistent-hint
              return-object
              single-line
              color="primary"
              outlined
            >
            </v-select>
          </v-col>

          <v-col cols="12" sm="3" class="mt-5">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
              dense
              small
              color="primary"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="1" align="right">
            <v-row justify="center">
              <v-dialog v-model="dialog" scrollable max-width="950" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 text-capitalize"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-plus-thick</v-icon> New
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="error--text text--darken-1">{{
                    formTitle
                  }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 500px">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" sm="12" md="12" dense>
                            <v-text-field
                              v-model="editedItem.title"
                              label="Email Title"
                              :rules="rules.required"
                              color="grey"
                              dense
                              outlined
                              class="mb-n3"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Email Title
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="12" class="my-n5">
                            <v-textarea
                              v-model="editedItem.message"
                              outlined
                              name="input-7-4"
                              label="Email Body"
                              color="grey"
                              dense
                              :rules="rules.required"
                              class="mb-n3"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Email Body
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-textarea>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.template"
                              :items="notificationTemplates"
                              label="Email Templates"
                              color="grey"
                              item-value="title"
                              item-text="title"
                              dense
                              outlined
                              return-object
                              @input="prefillText($event)"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Use Template?
                                </span>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.group"
                              :items="notificationGroups"
                              label="Send To"
                              item-text="name"
                              item-value="id"
                              color="grey"
                              dense
                              outlined
                              :rules="rules.select"
                            >
                              <template v-slot:label>
                                <span class="input__label"
                                  >Email Group
                                  <v-icon small color="error" class="mt-n2"
                                    >mdi-star-outline</v-icon
                                  ></span
                                >
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="text-capitalize mx-1"
                      dark
                      @click="close"
                    >
                      Cancel <v-icon small>mdi-cancel</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      class="text-capitalize mx-1"
                      :loading="loading"
                      @click="save"
                    >
                      {{ btnMessage }} <v-icon>mdi-send-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row align="center" justify="space-around">
          <v-btn depressed color="grey" fab small dark @click="editItem(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>

          <v-btn
            class="ml-n2"
            depressed
            color="error"
            fab
            small
            dark
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import EmailService from "../../services/email.service";
import NotificationTemplatesService from "../../services/notification_templates.service";
import User from "../../models/user";

export default {
  data: () => ({
    name: "Emails",
    items: [],
    editedIndex: -1,
    loading: true,

    editedItem: {
      title: "",
      message: "",
      group: "",
      template: "",
      status: ""
    },

    defaultItem: {
      title: "",
      message: "",
      group: "",
      template: "",
      status: ""
    },

    notification_status: [
      {
        id: 1,
        name: "Read",
      },
      {
        id: 2,
        name: "Unread",
      }
    ],

    page: "Emails",
    selected: [0],
    search: "",
    files: "",
    notificationGroups: [],
    notificationTemplates: [],
    user: new User("", ""),
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "At least one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
    },
    headers: [
      {
        text: "Email No",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Email Title",
        value: "title",
      },
      {
        text: "Email Body",
        value: "message",
      },
      {
        text: "Actions",
        align: "start",
        value: "actions",
        sortable: false,
        width: "10%",
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Email" : "Edit Email";
    },
    btnMessage() {
      return this.editedIndex === -1 ? "Send Email" : "Update Email";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllEmails();
    this.getCategories();
    this.getTemplates();
  },

  methods: {
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    prefillText(event) {
      this.editedItem.title = event.title;
      this.editedItem.message = event.details;
    },

    getCategories() {
      return EmailService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.notificationGroups = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getTemplates() {
      return NotificationTemplatesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.notificationTemplates = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllEmails() {
      return EmailService.getAllEmails().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        EmailService.deleteEmail(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          if (typeof this.editedItem.group != "number") {
            this.editedItem.group = this.editedItem.group.id;
          }

          EmailService.updateEmail(this.editedItem).then(
            (response) => {
              console.log(this.editedItem.group);
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                console.log(this.editedItem);
                this.getAllEmails();
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Invalid data, please check the form and try again!"
                  // response.response.statusText
                );
                this.getAllEmails();
                this.loading = false;
              }
            },
            (error) => {
              this.getAllEmails();
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }
          );
        } else {
          this.editedItem.status = 2;
          // create
          EmailService.storeEmail(this.editedItem).then(
            (response) => {
              console.log(this.editedItem.status);
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Email created successfully"
                );
                this.getAllEmails();
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch(
                  "alert/error",
                  response.response.statusText || "Internal error!"
                );
                this.loading = false;
                console.log(this.editedItem);
              }
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
